import React, { useEffect, useRef, useState } from 'react'
import { Pane, toaster } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import {
  customListStyles,
  formStyles,
  generalStyles,
  navStyles,
  sidesheetStyles,
  tableSectionStyles
} from '../../styles/sharedComponentStyles'
import { useQuery } from '@apollo/client'
import LoadingSpinner from '../LoadingSpinner'
import RealmBanner from './RealmBanner'
import { GET_REALM_BY_ID_QUERY } from '../../queries/getRealmById'
import RealmInfo from './RealmInfo'
import { GET_REALM_ERROR_MESSAGE } from './realmUtils'
import { useHandleRealmDetails } from '../../hooks/useHandleRealmDetails'

const useSharedStyles = createUseStyles({
  ...generalStyles,
  ...navStyles,
  ...customListStyles,
  ...tableSectionStyles,
  ...sidesheetStyles,
  ...formStyles
})

const RealmDetails = ({
  show,
  handleDetailsClose,
  realm,
  realmNames,
  parentSectionRef
}) => {
  const sharedClasses = useSharedStyles()
  const sideSheetEl = useRef(null)

  const { title, setHeader } = useHandleRealmDetails({
    sideSheetEl,
    parentSectionRef,
    handleDetailsClose,
    show,
    realm
  })

  return (
    <>
      <Pane ref={sideSheetEl} className={sharedClasses.sideSheet}>
        {realm && (
          <>
            <RealmBanner
              realm={realm}
              title={title}
            />

            <RealmInfo
              realm={realm}
              setHeader={setHeader}
              realmNames={realmNames}
            />
          </>
        )}
      </Pane>
    </>
  )
}

export default function RealmDetailsWrapper({ realmId, ...props }) {
  const [realm, setRealm] = useState()
  const { loading, data: currentRealm } = useQuery(GET_REALM_BY_ID_QUERY, {
    skip: !realmId,
    variables: {
      realmId
    },
    onError: _ => toaster.danger(GET_REALM_ERROR_MESSAGE)
  })

  useEffect(() => {
    if (currentRealm?.Realm_by_pk) {
      setRealm(currentRealm?.Realm_by_pk)
    }
  }, [currentRealm])

  if (loading) {
    return <LoadingSpinner />
  }

  return <>{realm && <RealmDetails {...props} realm={realm} />}</>
}

import gql from 'graphql-tag'

export const UPDATE_REALM_MUTATION = gql`
  mutation UpdateRealm($id: Int!, $realmName: String!, $notes: String) {
    update_Realm(
      where: { id: { _eq: $id } }
      _set: { name: $realmName, notes: $notes }
    ) {
      returning {
        id
        name
        notes
        createdAt
      }
    }
  }
`

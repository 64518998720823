import React, { useRef } from 'react'
import { login, isAuthenticated } from '../../utils/auth'
import { Pane } from 'evergreen-ui'
import LoadingSpinner from '../../components/LoadingSpinner'
import RealmDetailsWrapper from '../../components/RealmDetails'
import Can, { useCan } from '../../components/Can'
import { SYSTEM_ADMIN } from '../../utils/constants'
import { useHandleRealmList } from '../../hooks/useHandleRealmList'

const RealmPage = ({ params }) => {
  const { verifyRoles } = useCan()
  const canSeeRealms = verifyRoles(SYSTEM_ADMIN)
  const realmsSectionRef = useRef(null)
  const { loading, realmsSet } = useHandleRealmList({
    realmsSectionRef,
    canSeeRealms
  })

  if (!isAuthenticated()) {
    login()
    return (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <h2>Redirecting to login...</h2>
      </Pane>
    )
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <RealmDetailsWrapper
        realmId={params['realmId']}
        show={true}
        realmNames={realmsSet.map(r => r.name)}
        parentSectionRef={realmsSectionRef}
      />
    </>
  )
}

export default RealmPage

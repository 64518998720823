import gql from 'graphql-tag'

export const GET_REALM_BY_ID_QUERY = gql`
  query RealmByIdQuery($realmId: Int!) {
    Realm_by_pk(id: $realmId) {
      id
      isBlocked
      notes
      createdAt
      name
    }
  }
`

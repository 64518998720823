import * as yup from 'yup'
import { validateRepeatedName } from '../../utils/filters'

export const realmValidationSchema = realmNames =>
  yup.object().shape({
    name: yup
      .string()
      .typeError('Required')
      .required('Required')
      .trim()
      .test({
        message: 'A realm with this name already exist, please change it',
        name: 'realmName',
        test: value => !validateRepeatedName(realmNames, value)
      })
  })

import React from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pane } from 'evergreen-ui'
import { createUseStyles } from 'react-jss'
import { navStyles } from '../../styles/sharedComponentStyles'
import date from 'date-and-time'
import { navigate } from 'gatsby'

const useSharedStyles = createUseStyles({
  ...navStyles
})

const RealmBanner = ({ realm, title }) => {
  const sharedClasses = useSharedStyles()
  return (
    <nav className={sharedClasses.nav}>
      <Pane className={sharedClasses.secondaryNav}>
        <h1>
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={() => {
              if (typeof window !== 'undefined') {
                navigate('/realms')
              }
            }}
          />
          {title}
          <span>
            Created on {date.format(new Date(realm.createdAt), 'MM/DD/YYYY')}
          </span>
        </h1>
      </Pane>
    </nav>
  )
}

export default RealmBanner

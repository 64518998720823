import { useQuery } from '@apollo/client'
import { toaster } from 'evergreen-ui'
import { navigate } from 'gatsby'
import { useEffect, useState } from 'react'
import { GET_REALMS_ERROR_MESSAGE } from '../components/RealmDetails/realmUtils'
import { GET_ALL_REALMS_QUERY } from '../queries/getAllRealms'
import { useGlobalStore } from './useGlobalStore'

const ALL = 'All'

export function useHandleRealmList({ canSeeRealms, realmsSectionRef }) {
  const [realmsSetRaw, setRealmsSetRaw] = useState([])
  const [realmsSet, setRealmsSet] = useState([])
  const [filterValue, setFilterValue] = useState([])
  const [showRealmDetail, setShowRealmDetail] = useState(false)
  const [selectedRealm, setSelectedRealm] = useState()
  const [showCreateRealmDialog, setShowCreateRealmDialog] = useState(false)
  const { setShowPrompt } = useGlobalStore()

  const { loading, data: realms } = useQuery(GET_ALL_REALMS_QUERY, {
    skip: !canSeeRealms,
    onError: _ => toaster.danger(GET_REALMS_ERROR_MESSAGE)
  })

  function handleCreateRealmCloseModal() {
    setShowPrompt(false)
    setShowCreateRealmDialog(false)
  }
  function handleCreateRealmOpenModal() {
    setShowPrompt(true)
    setShowCreateRealmDialog(true)
  }

  useEffect(() => {
    function onGetRealmsSuccess(result) {
      const arr = result?.Realm || []
      const raw = [...arr]
      setRealmsSet(arr)
      raw.unshift({ name: ALL, dummyObj: true })
      setRealmsSetRaw(raw)
    }
    onGetRealmsSuccess(realms)
  }, [realms])

  function handleOpenSideSheet(realmId) {
    if (realmId) {
      setSelectedRealm({ id: realmId })
      setShowRealmDetail(true)
      if (realmsSectionRef) {
        realmsSectionRef.current.classList.add('hide')
      }
    }
  }

  function onRealmCreation(realm) {
    if (realm && realm.id > 0) {
      navigate('/realm/' + realm.id + '/studies')
    } else {
      navigate('/realms')
    }
  }

  function handleRealmFilter(query) {
    setFilterValue(query)
    if (query === '') {
      return setRealmsSet(realmsSetRaw)
    }

    setRealmsSet(
      realmsSetRaw.filter(realm => {
        const name = realm.name
        return name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
      })
    )
  }

  function handleRealmDetailsClose() {
    setShowRealmDetail(false)
  }

  return {
    realmsSet,
    loading,
    showRealmDetail,
    selectedRealm,
    showCreateRealmDialog,
    filterValue,
    handleCreateRealmCloseModal,
    handleCreateRealmOpenModal,
    handleRealmDetailsClose,
    handleRealmFilter,
    handleOpenSideSheet,
    onRealmCreation
  }
}

import { useEffect, useState } from 'react'
import { useGlobalStore } from './useGlobalStore'

export function useHandleRealmDetails({
  show,
  realm,
  sideSheetEl,
  parentSectionRef,
  handleDetailsClose
}) {
  const [title, setTitle] = useState(realm?.name)
  const { setShowPrompt } = useGlobalStore()

  function handleCloseSideSheet() {
    parentSectionRef.current.classList.remove('hide')
    sideSheetEl.current.classList.remove('show')
    setShowPrompt(false)
    handleDetailsClose()
  }

  function setHeader(header) {
    setTitle(header)
  }

  useEffect(() => {
    if (show) {
      setShowPrompt(true)
      sideSheetEl.current.classList.add('show')
    }
    return () => setShowPrompt(false)
  }, [setShowPrompt, show])

  return { title, handleCloseSideSheet, setHeader }
}

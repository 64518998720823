import React from 'react'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Heading, Pane, toaster } from 'evergreen-ui'
import { useForm, useFormState } from 'react-hook-form'
import { createUseStyles } from 'react-jss'
import {
  customListStyles,
  formStyles
} from '../../styles/sharedComponentStyles'
import myTheme, { breakpoints } from '../../styles/theme'
import InputTextField from '../forms/InputTextField'
import TextAreaField from '../forms/TextAreaField'
import { UPDATE_REALM_MUTATION } from '../../mutations/updateRealm'
import { UPDATE_REALM_ERROR, UPDATE_REALM_SUCCESS } from './realmUtils'
import { realmValidationSchema } from './realmFormSchemas'

const useSharedStyles = createUseStyles({
  ...formStyles,
  ...customListStyles,
  wrapper: {
    display: 'grid',
    width: '100%',
    maxWidth: '1200px',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    margin: '30px auto 0',
    overflowY: 'auto'
  },
  form: {
    width: '100%'
  },
  fieldsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [`@media (max-width: ${breakpoints.sm})`]: {
      gridTemplateColumns: 'auto'
    }
  },
  fieldsLeft: {
    display: 'grid',
    flexBasis: '50%',
    gridTemplateRows: '50px 100px',
    rowGap: '40px',
    padding: '20px'
  },
  fieldsRight: {
    display: 'grid',
    flexBasis: '50%',
    rowGap: '40px',
    padding: '20px',
    gridTemplateRows: '100px 100px'
  },
  buttons: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%'
  }
})

const RealmInfo = ({ realm, setHeader, realmNames }) => {
  const sharedClasses = useSharedStyles()
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      realmValidationSchema(realmNames?.filter(n => n !== realm.name))
    )
  })
  const { isDirty, isValid } = useFormState({ control })
  const disableSubmit = !isDirty || !isValid
  const [updateRealm, { loading: isSubmitting }] = useMutation(
    UPDATE_REALM_MUTATION,
    {
      onCompleted: result => {
        if (result?.update_Realm?.returning?.length > 0) {
          const data = result.update_Realm.returning[0]
          reset(data)
          setHeader(data.name)
          toaster.success(UPDATE_REALM_SUCCESS)
        }
      },
      onError: _ => toaster.danger(UPDATE_REALM_ERROR)
    }
  )

  const onSubmit = async data => {
    data.id = realm.id
    data.realmName = data.name
    await updateRealm({
      variables: {
        ...data
      }
    })
  }

  return (
    <Pane className={sharedClasses.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={sharedClasses.form}>
        <Pane
          backgroundColor={myTheme.colors.blueNavy}
          padding={30}
          display="flex"
          borderTopLeftRadius="6px"
          borderTopRightRadius="6px"
        >
          <Heading size={600} color="#fff">
            Realm Details
          </Heading>
        </Pane>
        <Pane className={sharedClasses.fieldsWrapper}>
          <Pane className={sharedClasses.fieldsLeft}>
            <InputTextField
              control={control}
              name="name"
              defaultValue={realm.name || ''}
              label="Name"
              required
              showErrorMessage
            />
          </Pane>
          <Pane className={sharedClasses.fieldsRight}>
            <TextAreaField
              control={control}
              name="notes"
              label="Notes"
              inputHeight={100}
              defaultValue={realm.notes || ''}
            />
            <Pane className={sharedClasses.buttons}>
              <Button
                className={sharedClasses.buttonStyles}
                type="submit"
                isLoading={isSubmitting}
                disabled={disableSubmit}
              >
                Save changes
              </Button>
            </Pane>
          </Pane>
        </Pane>
      </form>
    </Pane>
  )
}

export default RealmInfo
